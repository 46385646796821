import React from "react";
import { Container, Row, Col, CardDeck } from "react-bootstrap";
import ClassCard from "./classCard";
import LittleGirlFocusedImage from "../images/littleGirlFocused";
import BoysStudyingImage from "../images/boysStudying";
import BoyPaintingImage from "../images/boyPainting";
export default function PreschoolClassesOffered(props) {
  return (
    <section>
      <h2 className="text-center pb-2">Preschool Classes</h2>
      <Container>
        <Row>
          <Col>
            <CardDeck>
              <ClassCard
                title="THE ACORNS (3-YEAR-OLD)"
                // timePeriod="February 28, 2022"
                btnText="Explore"
                btnLink="/preschool/acorns"
                image={<LittleGirlFocusedImage />}
              />
              <ClassCard
                title="THE MAPLES PROGRAM (4-YEAR-OLD)"
                // timePeriod="December 31, 2020"
                btnText="Discover"
                btnLink="/preschool/maples"
                image={<BoysStudyingImage />}
              />
              <ClassCard
                title="THE OAKS (5-YEAR-OLD)"
                // timePeriod="December 31, 2020"
                btnText="Learn"
                btnLink="/preschool/oaks"
                image={<BoyPaintingImage />}
              />
            </CardDeck>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
