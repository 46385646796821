import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "gatsby";

export default function ClassCard({
  title,
  timePeriod,
  image,
  btnText,
  btnLink,
  children,
}) {
  return (
    <Card>
      {image}
      <Card.Body className="text-center">
        <Card.Subtitle>{timePeriod}</Card.Subtitle>
        <Card.Title>{title}</Card.Title>
        {children}
      </Card.Body>
      <Card.Footer className="text-center bg-white border-top-0">
        <Link className="btn btn-primary" to={btnLink}>
          {btnText}
        </Link>
      </Card.Footer>
    </Card>
  );
}
