import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import BadgeIcon from "../../images/svg/badge.inline.svg";
import AtomIcon from "../../images/svg/atom.inline.svg";
import BoardIcon from "../../images/svg/board.inline.svg";
import PeopleIcon from "../../images/svg/people.inline.svg";

export default function programHighlights() {
  return (
    <section className="pb-3">
      <h2 className="text-center mb-5">Program Highlights</h2>
      <Container>
        <Row className="text-center">
          <Col xs={12} sm={3}>
            <BadgeIcon />
            <h3>Certified</h3>
            <p>
              Certified and experienced teachers licensed under Alberta Child
              Care Licensing Act.
            </p>
          </Col>
          <Col xs={12} sm={3}>
            <PeopleIcon />
            <h3>Individualized</h3>
            <p>
              A low teacher-student ratio ensures that each student receives the
              attention they deserve.
            </p>
          </Col>
          <Col xs={12} sm={3}>
            <AtomIcon />
            <h3>Development</h3>
            <p>Activities centres designed to teach a variety of skills. </p>
          </Col>
          <Col xs={12} sm={3}>
            <BoardIcon />
            <h3>Activities</h3>
            <p>
              Interactive activities on Science, Math, Music, Art and field trips.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
