import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { LeftFeatureBox, RightFeatureBox } from "./featureBox";
import ChildWitHBlocks from "../images/childWithBlocks";
import JournalIcon from "../../images/svg/journal.inline.svg";
import BullseyeIcon from "../../images/svg/bullseye.inline.svg";
import SchoolIcon from "../../images/svg/school.inline.svg";
import PaletteIcon from "../../images/svg/palette.inline.svg";

export default function FeaturedPreschool() {
  return (
    <section className="pb-3" id="featured-preschool">
      <Container>
        <Row>
          <Col className="text-center pb-3 pb-lg-5">
            <h1 className="text-center pb-1">Great Minds Academy Preschool</h1>
            <p className="lead">A Place Where Your Child Will Love To Learn</p>
            <p>
              Our incredible teachers are here to offer the safest place and
              most inspiring learning environment for your preschooler. Our
              “learn through play” philosophy will still apply but more
              structured activities will be integrated into our daily program
              plans based on current interests of the children. Creativity will
              be encouraged through many different types of media, and social
              opportunities will be plentiful. The crafts and activities that
              our preschoolers participate in tend to fall in theme with
              seasonal activities such as fall, winter, spring, Valentine’s Day,
              Mother’s Day, Thanksgiving, Halloween, the Holiday Season, and
              Father’s Day. Math, science and literature will be paramount to
              ensure our students have a fully rounded experience.
            </p>
          </Col>
        </Row>
        <Row>
          {/*Left Column*/}
          <Col xs={12} md={4} className="my-auto">
            <Row>
              <LeftFeatureBox
                heading="Our Philosophy"
                body="Research-based, comprehensive curriculum that features
                      exploration and discovery as a way of learning."
                image={<JournalIcon />}
              />
              <LeftFeatureBox
                heading="Our Mission"
                body="To enable every child to discover the wonders of childhood
                      through creative play and social interaction."
                image={<BullseyeIcon />}
              />
            </Row>
          </Col>
          {/* Center Image */}
          <Col xs={12} md={4} className="pb-4">
            <ChildWitHBlocks className="rounded" />
          </Col>
          {/*Right Column*/}
          <Col xs={12} md={4} className="my-auto">
            <Row>
              {/*Low Teacher : Student Ratio*/}
              <RightFeatureBox
                heading="Low Teacher : Student Ratio"
                body="Understanding every child is different and so are their development needs."
                image={<PaletteIcon />}
              />
              {/*Staff*/}
              <RightFeatureBox
                heading="Staff"
                body="Talented and dedicated team of early childhood
                      professionals that strive to deliver quality care for the
                      children and families we serve."
                image={<SchoolIcon />}
              />
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
