import React from "react";
import { Row, Col } from "react-bootstrap";

export function LeftFeatureBox({ heading, body, image }) {
  return (
    <Col xs={12}>
      <Row className="text-center text-lg-right">
        <Col
          xs={{ span: 12, order: 2 }}
          md={{ span: 10, order: 1 }}
          className="pb-2"
        >
          <h5>{heading}</h5>
          <p>{body}</p>
        </Col>
        <Col
          xs={{ span: 12, order: 1 }}
          md={{ span: 2, order: 2 }}
          className="my-auto pb-2 pt-2"
        >
          {image}
        </Col>
      </Row>
    </Col>
  );
}

export function RightFeatureBox({ heading, body, image }) {
  return (
    <Col xs={12}>
      <Row className="text-center text-lg-left">
        <Col xs={12} md={2} className="my-auto pb-2 pt-2">
          {image}
        </Col>
        <Col xs={12} md={10} className="pb-2">
          <h5>{heading}</h5>
          <p>{body}</p>
        </Col>
      </Row>
    </Col>
  );
}
