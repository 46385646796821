import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

export default function ChildWitHBlocks({ className }) {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "child-with-blocks.jpg" }) {
        childImageSharp {
          fluid(fit: INSIDE) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Img
      fluid={data.file.childImageSharp.fluid}
      style={{ height: "60", width: "100%" }}
      className={className}
      imgStyle={{ objectFit: "fit" }}
    />
  );
}
